var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StudentSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _vm.$_userMixins_isSchoolUser && !_vm.$store.state.forceStudentView
            ? _c("StudentHeaderPortlet", { attrs: { "display-wide": true } })
            : _vm._e(),
          _c(
            "b-overlay",
            {
              attrs: {
                variant: "transparent",
                "no-center": "",
                opacity: "1",
                blur: "10px",
                show: _vm.encryptionEnabled,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center pt-4 kt-font-lg" },
                        [
                          _vm._v(
                            " PII is currently encrypted. Please unlock PII to view this page. "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "text-center pt-4" }, [
                        _vm.encryptionEnabled
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-brand btn-bold btn-upper mt-4",
                                staticStyle: { "min-width": "160px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.decrypt()
                                  },
                                },
                              },
                              [_vm._v(" Unlock PII ")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "kt-portlet" }, [
                _c("div", { staticClass: "kt-portlet__body p-3" }, [
                  _c(
                    "div",
                    { staticClass: "kt-widget kt-widget--user-profile-3" },
                    [
                      _c("div", { staticClass: "kt-widget__top" }, [
                        _c("div", { staticClass: "kt-widget__content pl-3" }, [
                          _c("div", { staticClass: "kt-widget__head" }, [
                            _c("h3", { staticClass: "kt-widget__title" }, [
                              _vm._v(" Reports "),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.filteredReports, function (report, idx) {
                  return _c(
                    "div",
                    {
                      key: `report_${report.reportId}_${idx}`,
                      staticClass: "col-lg-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-portlet kt-portlet--solid kt-portletk-portlet--height-fluid",
                          class: {
                            "kt-portlet--skin-solid kt-bg-warning":
                              report.reportType === "Report Card",
                          },
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openReport(report)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-portlet__head kt-portlet__head--noborder",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-portlet__head-label" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "kt-portlet__head-title",
                                      class: {
                                        "kt-link kt-link--state kt-link--primary":
                                          report.reportParams.reportType ===
                                          "Course Level",
                                        "kt-link kt-link--state kt-link--success":
                                          report.reportParams.reportType ===
                                          "School Level",
                                      },
                                      style: {
                                        color:
                                          report.reportType === "Report Card"
                                            ? "Black"
                                            : "",
                                      },
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.openReport(report)
                                        },
                                      },
                                    },
                                    [
                                      report.reportType === "Report Card"
                                        ? _c("i", {
                                            staticClass:
                                              "la la-certificate fa-lg",
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        " " + _vm._s(report.reportType) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "kt-portlet__head-toolbar" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-icon btn-icon-md",
                                      class: {
                                        "btn-warning":
                                          report.reportType === "Report Card",
                                        "btn-clean":
                                          report.reportType !== "Report Card",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.openReport(report)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "flaticon-download",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "kt-portlet__body pt-0 mt-0" },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-font-bold",
                                    style: {
                                      color:
                                        report.reportType === "Report Card"
                                          ? "Black"
                                          : "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          report.reportType === "Report Card"
                                            ? "Released"
                                            : "Generated"
                                        ) +
                                        " on "
                                    ),
                                    _c("br"),
                                    _c("code", [
                                      _vm._v(_vm._s(report.created)),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "mt-3" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--bold kt-badge--inline kt-font-lg",
                                      class: {
                                        "kt-badge--unified-success":
                                          report.reportParams.reportType ===
                                            "School Level" &&
                                          report.reportType !== "Report Card",
                                        "kt-badge--unified-primary":
                                          report.reportParams.reportType ===
                                          "Course Level",
                                        "kt-badge--success":
                                          report.reportType === "Report Card",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            report.reportParams.reportType ===
                                              "School Level"
                                              ? "School Level"
                                              : _vm.getReportCourse(report)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _c("PDFViewModal", { ref: "pdfViewModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }