<template>
<div>
    <StudentSubHeader>
        <template #buttons />
    </StudentSubHeader>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <StudentHeaderPortlet
            v-if="$_userMixins_isSchoolUser && !$store.state.forceStudentView"
            :display-wide="true"
        />

        <b-overlay
            :variant="'transparent'"
            no-center
            opacity="1"
            blur="10px"
            :show="encryptionEnabled"
        >
            <template #overlay>
                <div class="text-center pt-4 kt-font-lg">
                    PII is currently encrypted. Please unlock PII to view this page.
                </div>
                <div class="text-center pt-4">
                    <button
                        v-if="encryptionEnabled"
                        type="button"
                        style="min-width: 160px;"
                        class="btn btn-label-brand btn-bold btn-upper mt-4"
                        @click="decrypt()"
                    >
                        Unlock PII
                    </button>
                </div>
            </template>
            <div class="kt-portlet">
                <div class="kt-portlet__body p-3">
                    <div class="kt-widget kt-widget--user-profile-3">
                        <div class="kt-widget__top">
                            <div class="kt-widget__content pl-3">
                                <div class="kt-widget__head">
                                    <h3 class="kt-widget__title">
                                        Reports
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                    v-for="(report, idx) in filteredReports"
                    :key="`report_${report.reportId}_${idx}`"
                    class="col-lg-4"
                >
                    <div
                        class="kt-portlet kt-portlet--solid kt-portletk-portlet--height-fluid"
                        :class="{'kt-portlet--skin-solid kt-bg-warning': report.reportType === 'Report Card'}"
                        style="cursor: pointer;"
                        @click.stop.prevent="openReport(report)"
                    >
                        <div class="kt-portlet__head kt-portlet__head--noborder">
                            <div class="kt-portlet__head-label">
                                <a
                                    href="#"
                                    class="kt-portlet__head-title"
                                    :class="{
                                        'kt-link kt-link--state kt-link--primary': report.reportParams.reportType === 'Course Level',
                                        'kt-link kt-link--state kt-link--success': report.reportParams.reportType === 'School Level',
                                    }"
                                    :style="{'color': report.reportType === 'Report Card' ? 'Black' : ''}"
                                    @click.stop.prevent="openReport(report)"
                                >
                                    <i
                                        v-if="report.reportType === 'Report Card'"
                                        class="la la-certificate fa-lg"
                                    />
                                    {{ report.reportType }}
                                </a>
                            </div>
                            <div class="kt-portlet__head-toolbar">
                                <button
                                    class="btn btn-sm btn-icon btn-icon-md"
                                    :class="{
                                        'btn-warning': report.reportType === 'Report Card',
                                        'btn-clean': report.reportType !== 'Report Card',
                                    }"
                                    @click.stop.prevent="openReport(report)"
                                >
                                    <i class="flaticon-download" />
                                </button>
                            </div>
                        </div>
                        <div class="kt-portlet__body pt-0 mt-0">
                            <div>
                                <div
                                    class="kt-font-bold"
                                    :style="{'color': report.reportType === 'Report Card' ? 'Black' : ''}"
                                >
                                    {{ report.reportType === 'Report Card' ? 'Released' : 'Generated' }} on
                                    <br><code>{{ report.created }}</code>
                                </div>

                                <div class="mt-3">
                                    <span
                                        class="kt-badge kt-badge--bold kt-badge--inline kt-font-lg"
                                        :class="{
                                            'kt-badge--unified-success': report.reportParams.reportType === 'School Level' && report.reportType !== 'Report Card',
                                            'kt-badge--unified-primary': report.reportParams.reportType === 'Course Level',
                                            'kt-badge--success': report.reportType === 'Report Card'
                                        }"
                                    >
                                        {{ report.reportParams.reportType === 'School Level' ? 'School Level' : getReportCourse(report) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>

    <PDFViewModal ref="pdfViewModal" />
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as network from '../network';
import Types from '../store/Types';
import studentMixins from '../store/mixins/studentMixins';
import userMixins from '../store/mixins/userMixins';
import courseMixins from '../store/mixins/courseMixins';
import reportCardMixins from '../store/mixins/reportCardMixins';
import StudentSubHeader from './StudentSubHeader.vue';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';
import PDFViewModal from '../components/PDFViewModal.vue';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
    name: 'StudentReports',
    components: {
        StudentSubHeader,
        StudentHeaderPortlet,
        PDFViewModal,
    },
    mixins: [studentMixins, userMixins, courseMixins, reportCardMixins],
    data() {
        return {
            key: 0,
            reports: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        studentEnrollmentId() {
            const { student } = this;
            return student.studentEnrollmentId;
        },
        studentCourses() {
            const { studentEnrollmentId } = this;
            if (!studentEnrollmentId) return [];
            return this.$_courseMixins_getCoursesForStudentEnrollmentId(studentEnrollmentId);
        },
        filteredReports() {
            const { reports, $store } = this;
            const { schoolTermMarkingPeriodId } = $store.state.settings;
            if (!reports || !reports.length) return [];

            return reports.sort((a, b) => moment(b.created).diff(a.created)).reduce((acc, report) => {
                const { reportType } = report;
                if (reportType === 'Report Card') return [report, ...acc];
                return [...acc, report];
            }, []).filter((r) => {
                if (r.schoolTermMarkingPeriodId != schoolTermMarkingPeriodId) return false;
                if (this.$_userMixins_isSchoolUser && !$store.state.forceStudentView) return true;

                const { showOnPortfolio } = r;
                return showOnPortfolio;
            }).map((r) => ({
                ...r,
                created: moment(r.created).utc().format('YYYY-MM-DD HH:mm A'),
            }))
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        refresh() {
            this.key += 1;
        },
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        populate() {
            const v = this;
            const { schoolTermId, schoolId } = this.user.school;
            const { studentEnrollmentId } = this;

            network.reports.getReportsForStudent({ url: { schoolTermId, schoolId, studentEnrollmentId } }, (err, res) => {
                if (err) return v.showError('Unable to get reports for student');
                const { reports } = res;
                v.reports = reports;
            });
        },
        getReportCourse(report) {
            const { studentCourses } = this;
            const { courseSectionId } = report;
            const course = studentCourses.find((c) => c.courseSectionId === courseSectionId);
            if (!course) return 'Course Level';
            return course.courseSectionTitle;
        },
        openReport(report) {
            const { reportDefinition } = report;
            const pdf = pdfMake.createPdf(reportDefinition);
            pdf.getDataUrl((url) => {
                this.$refs.pdfViewModal.open(url);
            });
        },
    },
};
</script>
